import * as styledComponents from "styled-components"

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
} = styledComponents

export { css, createGlobalStyle, keyframes, ThemeProvider, withTheme }
export default styled
