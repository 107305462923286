import GlobalStyle from "./GlobalStyle"
import theme from "./theme/theme"
import styled, {
  createGlobalStyle,
  css,
  keyframes,
  ThemeProvider,
  withTheme,
} from "./theme/styled.js"

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  theme,
  GlobalStyle,
}
export default styled
