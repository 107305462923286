import styled from "styled-components"

const TextBlock = styled.p`
  padding-bottom: 25px;
  line-height: 2.5rem;
  &.centered {
    text-align: center;
  }

  &.white {
    color: black;
    @media screen and (min-width: 40em) {
      color: white;
    }
  }

  &.large {
    font-size: 1.2em;
  }

  &.fancy {
    font-family: "Patrick Hand", serif;
  }

  &.focus {
    font-weight: 700;
  }

  &.slanted {
    font-style: italic;
  }
`

export default TextBlock
