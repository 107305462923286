import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import BurnOut from "../modules/specialisaties/BurnOut"

const BurnOutPage = () => (
  <GlobalCollection>
    <BurnOut />
  </GlobalCollection>
)

export default BurnOutPage
