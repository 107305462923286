import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import { Link } from "gatsby"

const Depressie = () => (
  <DepressieWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Burn-out</h1>
    </Flex>
    <Box p={3}>
      <StyledLink to="/specialisaties"> Terug</StyledLink>
      <TextBlock className="slanted centered">
        Is de kans krijgen om jezelf en jouw talenten te herontdekken.
      </TextBlock>
      <TextBlock className="slanted centered">
        Een weg naar een bewuster en gelukkiger leven.
      </TextBlock>
      <TextBlock>
        Zelf ben ik hersteld van een burn-out en heb ik de weg afgelegd naar een
        nieuwe start. Als ervaringsdeskundige kan ik samen met jou de weg
        bewandelen naar verandering. Om mijzelf te ondersteunen in mijn
        genezingsproces, heb ik meerdere cursussen en workshops gevolgd.
        Daarenboven leerde ik tijdens mijn opleiding als psychotherapeut hoe
        cliënten te begeleiden in dit proces.
      </TextBlock>
      <TextBlock>
        Een burn-out ontstaat niet van de ene dag op de andere. Vaak is het een{" "}
        <b>sluimerend proces dat al zeer lang bezig is</b>. Een toenemende
        ontevredenheid die meestal op het werk begint en zich over steeds meer
        levensterreinen gaat spreiden. Mensen blijven vaak lang doorgaan, slepen
        zich voort en gaan gebukt onder gevoelens van uitputting. Ze blijven tot
        het uiterste doorgaan en dan is er de druppel... Op een gegeven moment
        zegt het lichaam STOP. De symptomen zijn divers en soms vaag, niet bij
        iedereen gelijk en toch zijn er een aantal kenmerken die van burn-out
        iets specifiek maken, namelijk dat het energiepeil en de stressregulatie
        verstoord zijn.
      </TextBlock>
      <TextBlock>
        Een belangrijke stap in{" "}
        <b>
          herstel is het herkennen en vervolgens ook erkennen van je burn-out
        </b>
        . Huilen om niets, paniekaanvallen, depressieve gevoelens, boosheid,
        frustraties,... Je krijgt lichamelijk klachten waarvan je niet eens wist
        dat ze bestonden. Je bent de controle kwijt. Aanvaarden en niet langer
        vechten om toch maar te kunnen blijven doorgaan. Verdere stappen zijn
        vooral ophouden met 'doen' en{" "}
        <b>op een heel nieuwe manier 'luisteren' naar jezelf</b>. Het is
        belangrijk om terug balans te vinden in je leven vanuit jouw behoeftes,
        zodat je jezelf niet meer voorbijloopt.
      </TextBlock>
      <TextBlock>
        Omwille van de complexiteit van een burn-out verkennen we verschillende
        domeinen en gaan we te werk met verscheidene strategieën.
      </TextBlock>
      <TextBlock>
        Door het beoefenen van hartcoherentie (zie specialisaties) verkrijg je
        een solider emotioneel platform dat helpt bij het genezingsproces van
        een burn-out. Het is een ademtechniek dat het rustgevoel en welbehagen
        bevordert, het stressgehalte vermindert, het geeft energie, het
        verbetert de concentratie en focus en men komt tot een betere beheersing
        van angsten. Indien je interesse hebt, kunnen we ook hiermee aan de
        slag.
      </TextBlock>
      <TextBlock>
        Heb je nog vragen of wil je graag een afspraak maken? Dat kan online op
        deze website via het contactformulier.
      </TextBlock>
    </Box>
  </DepressieWrapper>
)

export default Depressie

const DepressieWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`
const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
  display: block;
  margin-bottom: 25px;
  font-size: 1.1em;
`
